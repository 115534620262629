import { render, staticRenderFns } from "./modal-company-details.html?vue&type=template&id=d9022e9a&scoped=true&external"
import script from "./modal-company-details.js?vue&type=script&lang=js&external"
export * from "./modal-company-details.js?vue&type=script&lang=js&external"
import style0 from "./modal-company-details.scss?vue&type=style&index=0&id=d9022e9a&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9022e9a",
  null
  
)

export default component.exports